import {
  Flex,
  NextLinkFromReactRouter,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import { NetworkSwitcher } from 'components/ENetworkSwitcher'
import UserMenu from '../UserMenu'

const MenuContainer = styled.div`
  font-size: 16px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: black;
  // font-size: 12px;
  // font-weight: 700;
  // border-right: 1px solid #72e9c1;
  padding: 0 16px;
  z-index: 1;

  @media screen and (max-width: 851px) {
    height: 76px;
  }
`

const BrandImage = styled.img`
  width: 100px;
  object-fit: contain;

  @media screen and (max-width: 499px) {
    display: none;
  }
`
const MobileBrandImage = styled.img`
  width: 30px;
  object-fit: contain;
  flex-grow: 1;
  @media screen and (min-width: 500px) {
    display: none;
  }
`
const MenuItem = styled.div`
  cursor: pointer;
  position: relative;
  .submenu {
    position: absolute;
    display: none;
    margin: 0;
    padding: 0.5em 0;
    border: 2px solid #fff2;
    border-radius: 0.5em;
    min-width: 15em;
    text-transform: uppercase;
  }

  &:hover {
    .submenu {
      display: flex;
    }
  }

  > a {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    border-radius: 15px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.textSubtle};

    &:hover {
      background-color: ${({ theme }) => theme.colors.tertiary};
    }

    &.active {
      color: rgb(48, 167, 95);
      font-weight: bold;
    }

    &.link-v2 {
      gap: 6px;
      &::after {
        background: #0F0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
      }
    }

    & .active-icon {
      fill: ${({ theme }) => theme.colors.primary};
      position: absolute;
      left: 10px;
      top: 6px;
    }
  }


  @media screen and (max-width: 851px) {
    display: none;
  }
`

const SubMenuItem = styled.div`
  cursor: pointer;
  position: relative;
  padding: 1em;
  color: ${({ theme }) => theme.colors.textSubtle};

  &:hover {
    background-color: ${({ theme }) => theme.colors.tertiary};
  }

  @media screen and (max-width: 851px) {
    display: none;
  }
`

const SubMenuActive = styled.div`
  background: white;
  width: 4px;
  height: 4px;
  border-radius: 15px;
  position: absolute;
  top: 8px;
  left: -8px;
`

const SocialLinks = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 24px;

  @media screen and (max-width: 851px) {
    display: none;
  }
`

const MobileMenu = styled(Flex)`
  gap: 4px;

  @media screen and (min-width: 851px) {
    display: none;
  }
`
const DesktopMenu = styled(Flex)`
  gap: 4px;
  flex-direction: row;
  margin: 10px 2px;
  justify-content: space-around;

  @media screen and (max-width: 851px) {
    display: none;
  }
`

const Highlights = styled.span`
color: #01FF00;
`

const LinkComponent = (linkProps: any) => {
  return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
}

const LeftMenu = ({ menuItems, activeItem, activeSubItem }) => {
  return (
    <MenuContainer>
      <Flex justifyContent="space-between">
        <BrandImage src="/assets/nav-title-dark.png" />
        <MobileBrandImage src="/assets/nav-title-light.png" />
      </Flex>

      <Flex flexGrow={1} ml="8px">
        {menuItems.map((item) => (
          <MenuItem key={item.label}>
            <LinkComponent href={item.href} className={item === activeItem ? "active" : ""} disable={!!item.items}>
              {item.label}
            </LinkComponent>
            {item.items ? (
              <Flex className="submenu" flexDirection="column">
                {item.items.map((subItem) => (
                  <SubMenuItem key={subItem.label}>
                    <LinkComponent href={subItem.href} style={{ position: 'relative' }}>
                      {/* {subItem === activeSubItem && <SubMenuActive />} */}
                      {subItem.label}
                      {subItem.highlights && (<Highlights>{subItem.highlights}</Highlights>)}
                    </LinkComponent>
                  </SubMenuItem>
                ))}
              </Flex>
            ) : null}
          </MenuItem>
        ))}
        <MenuItem>
          <LinkComponent href="https://app.9inch.io" className="link-v2">
            9INCH v2
          </LinkComponent>
        </MenuItem>
      </Flex>

      <DesktopMenu>
        <NetworkSwitcher />
        <UserMenu />
      </DesktopMenu>

      <MobileMenu>
        <NetworkSwitcher />
        <UserMenu />
      </MobileMenu>

      {/* <SocialLinks>
        <DiscordIcon href="" target="_blank" cursor="pointer" />
        <TelegramIcon href="" target="_blank" cursor="pointer" />
        <TwitterIcon href="" target="_blank" cursor="pointer" />
        <GithubIcon href="" target="_blank" cursor="pointer" />
        <MediumIcon href="" target="_blank" cursor="pointer" />
      </SocialLinks> */}
    </MenuContainer>
  )
}

export default LeftMenu
