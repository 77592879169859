import { ContextApi } from '@pancakeswap/localization'
import { DropdownMenuItems, MenuItemsType, SwapFillIcon, SwapIcon } from '@pancakeswap/uikit'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Swap'),
        href: '/swap',
        showItemsOnMobile: true,
      },
      {
        label: t('Liquidity'),
        href: '/add',
        showItemsOnMobile: true,
        items: [
          {
            label: t('Add Liquidity'),
            href: '/add',
            showItemsOnMobile: true,
          },
          {
            label: t('Positions'),
            href: '/positions',
            showItemsOnMobile: true,
          },
          {
            label: t('Stableswap '),
            highlights: "NEW!",
            href: '/add/0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07/0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f',
            showItemsOnMobile: true,
          },
        ]
      },
      // {
      //   label: t('HyperPools'),
      //   href: '/hyperpools',
      //   showItemsOnMobile: true,
      // },
      // {
      //   label: t('xNineinch'),
      //   href: '/xnineinch',
      //   showItemsOnMobile: true,
      //   items: [
      //     {
      //       label: t('Dashboard'),
      //       href: '/xnineinch',
      //       showItemsOnMobile: true,
      //     },
      //     {
      //       label: t('Dividends'),
      //       href: '/xnineinch/dividends',
      //       showItemsOnMobile: true,
      //     },
      //     {
      //       label: t('Launchpad'),
      //       href: '/xnineinch/launchpad',
      //       showItemsOnMobile: true,
      //     },
      //     {
      //       label: t('Yield Booster'),
      //       href: '/xnineinch/booster',
      //       showItemsOnMobile: true,
      //     },
      //   ],
      // },
      // {
      //   label: t('Launchpad'),
      //   href: '/launchpad',
      //   showItemsOnMobile: true,
      // },
      /* {
        label: t('Gauge'),
        href: '/gauge',
        showItemsOnMobile: true,
      }, */
      {
        label: t('Stats'),
        href: '/info/v3',
        showItemsOnMobile: true,
      }
    ].map((item) => addMenuItemSupported(item, chainId))

export default config


